import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { getVisitorsDetails } from "../actions/userActions";

const LoginScreen = ({ history }) => {
  const dispatch = useDispatch();
  const visitorsList = useSelector((state) => state.visitorsList);
  const { loading, error, visitors } = visitorsList;
  useEffect(() => {
    dispatch(getVisitorsDetails());
  }, []);

  return (
    <>
      <h1>Visitors Screen</h1>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : visitors ? (
        <>
          <div>
            <h3>Total de Visitantes: {visitors.reduce((acc, visitors) => visitors.NumberOfVisitors + acc,0)  } </h3>
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Region</th>
                  <th>Ciudad</th>
                  <th>No. Visitors</th>
                </tr>
              </thead>
              <tbody>
                {visitors.map((visitor) => (
                  <tr key={visitor.Country}>
                    <td>{visitor.Country}</td>
                    <td>{visitor.Region}</td>
                    <td>{visitor.Ciudad}</td>
                    <td>{visitor.NumberOfVisitors}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoginScreen;
