import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listUsers, deleteUserById } from "../actions/userActions";
import Paginate from "../components/Paginate";

const UserListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pageNumber } = useParams() || 1;

  let admins = false;

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, page, userPages } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers(false, "", pageNumber));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, successDelete, admins, pageNumber]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteUserById(id));
    }
  };

  const activateAdmins = (admin) => {
    admins = admin;
  };

  const userSearch = (e) => {
    if (e.target.value === "") {
      if (admins) {
        dispatch(listUsers(admins));
      } else {
        dispatch(listUsers());
      }
    } else if (e.target.value.length > 3) {
      dispatch(listUsers(false, e.target.value));
    }
    //console.log(e.target.value);
  };

  return (
    <>
      <h1>Users</h1>
      <Row className="align-items-center" style={{ marginBottom: "1rem" }}>
        <Col className="text-center">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onChange={(e) => {
                activateAdmins(e.target.checked);
                dispatch(listUsers(admins));
              }}
            ></input>
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Admins
            </label>
          </div>
        </Col>
        <Col className="text-center">
          <Form.Control
            type="email"
            placeholder="Email Search"
            onChange={(e) => {
              userSearch(e);
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : users ? (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Administrador</th>
                <th>Confirmado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>

                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>

                  <td>
                    {user.isAdmin ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>

                  <td>
                    {user.confirmed ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>

                  <td>
                    <Link to={`/admin/user/${user._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </Link>

                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(user._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={userPages}
            page={page}
            isAdmin={true}
            isUserList={true}
          ></Paginate>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserListScreen;
