import React, { useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  ListGroupItem,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import { addToCart, removeFromCart } from "../actions/cartActions";

const CartScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let { id } = useParams();
  const productId = id;

  //console.log(location.search); //?qty=3
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { cartItems } = cart;
  const { userInfo } = userLogin;
  //console.log(cartItems);

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty)); //cartActions.js
    }
  }, [dispatch, productId, qty]);

  const removeFromCartHandler = (id) => {
    //console.log(id);
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/shipping");
  };
  const LoginHandler = () => {
    navigate("/login");
  };

  return (
    <Row>
      <Col md={8}>
        {" "}
        <h1>Carrito de Compras</h1>{" "}
        {cartItems.length === 0 ? (
          <Message>
            Tu carrito de compras está vacío{" "}
            <Link to="/">Regresar a Productos</Link>{" "}
          </Message>
        ) : (
          <div>
            <ListGroup variant="flush">
              {cartItems.map((item) => (
                <ListGroup.Item key={item.product}>
                  <Row>
                    <Col md={2}>
                      <Image
                        src={item.image}
                        alt={item.name}
                        fluid
                        rounded
                      ></Image>
                    </Col>
                    <Col md={3}>
                      <Link to={`/product/${item.product}`}>{item.name}</Link>
                    </Col>
                    <Col md={2}>${item.price}</Col>
                    <Col md={2}>
                      {" "}
                      <Form.Select
                        value={item.qty}
                        onChange={(e) => {
                          dispatch(
                            addToCart(item.product, Number(e.target.value))
                          );
                        }}
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={2}>
                      {" "}
                      <button
                        type="button"
                        variant="light"
                        onClick={() => {
                          removeFromCartHandler(item.product);
                        }}
                      >
                        {" "}
                        <i className="fas fa-trash"></i>{" "}
                      </button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="mt-5 mb-5 ">
              <Link className="mt-5" style={{textDecoration:'underline' }} to="/">
                Seguir Comprando
              </Link>
            </div>
          </div>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                Productos
              </h2>
              $
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)}
            </ListGroup.Item>
            <ListGroup.Item>
              {userInfo ? (
                cartItems.reduce((acc, item) => acc + item.qty, 0) <= 10 ? (
                  <Button
                    type="button"
                    className="btn-block"
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                  >
                    Checkout
                  </Button>
                ) : (
                  <Button type="button" className="btn-block" disabled>
                    No puedes comprar más de 10 productos por pedido
                  </Button>
                )
              ) : (
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cartItems.length === 0}
                  onClick={LoginHandler}
                >
                  Login
                </Button>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default CartScreen;
