import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col ,InputGroup} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { register } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { set } from "mongoose";

const RegisterScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [confirmPassMessage, setConfirmPassMessage] = useState(null);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userRegisterInfo } = userRegister;

  const redirect = location.search ? location.search.split("=")[1] : "/login";

  useEffect(() => {
    if (userRegisterInfo) {
      setConfirmPassMessage("Por favor confirma tu correo electrónico para poder ingresar. Revisa tu bandeja de entrada o correo no deseado.");
      // setTimeout(() => {
      // navigate(redirect);
      // }, 10000); // Redirect to login after 10 seconds
    }
  }, [navigate, userRegisterInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    //DISPATCH REGISTER
    if(password !== ConfirmPassword){
        setMessage('Las contraseñas no coinciden');
    }else{
        dispatch(register(name,email, password));
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  return (
    <FormContainer>
      <h1>Registro</h1>
      {confirmPassMessage && <Message variant="info">{confirmPassMessage}</Message>}
      {message && <Message variant="danger">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader></Loader>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese su nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Dirección de correo electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingrese su correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Contraseña</Form.Label>
          <InputGroup>
            <Form.Control
              type={passwordVisible ? "text" : "password"}
              placeholder="Ingrese su contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
            <Button variant="" onClick={togglePasswordVisibility}>
                <i className={passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}></i>
              </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirmar contraseña</Form.Label>
          <InputGroup>
            <Form.Control
              type={passwordVisible ? "text" : "password"}
              placeholder="Confirme su contraseña"
              value={ConfirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
              <Button variant="" onClick={togglePasswordVisibility}>
                <i className={passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}></i>
              </Button>
          </InputGroup>
        </Form.Group>
        <Button type="submit" variant="primary">
        Registrarse
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
        ¿Ya tienes una cuenta?{" "}
          <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
          Iniciar sesión
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;
