import React, { useRef, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
//call action = useDispatch
//bring something from state = useSelector

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  let windowWidth = useRef(window.innerWidth);

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    window.addEventListener("resize", recalculateWidth);
    return () => {
      window.removeEventListener("resize", recalculateWidth);
    };
  }, [windowWidth]);

  const recalculateWidth = () => {
    windowWidth.current = window.innerWidth;
  };

  return (
    <div>
      <hr></hr>
      <div className="container position-relative">
        <Row
          className="d-flex justify-content-center"
          style={{ textAlign: "justify" }}
        >
          <Col sm={12} md={4} lg={4} xl={4} className="mb-10 mb-lg-0">
            <img
              style={{ width: "50%", borderRadius: "20%" }}
              className="img-fluid mx-auto d-block"
              src={require("../img/bottle1_1.png")}
              alt="bottle.jpg"
            ></img>
            <h3 style={{ textAlign: "center" }}>Fórmulas Avanzadas:</h3>
            Nuestros productos cuentan con formulaciones avanzadas que son
            meticulosamente elaboradas para abordar diversas preocupaciones del
            cuidado de la piel. Desde soluciones anti-envejecimiento hasta
            potenciadores de hidratación y fórmulas para combatir el acné, cada
            producto está diseñado para ofrecer resultados específicos,
            respaldados por la investigación científica más reciente y los
            ingredientes más innovadores.
          </Col>
          <Col sm={12} md={4} lg={4} xl={4} className="mb-10 mb-lg-0">
            <img
              style={{ width: "50%", borderRadius: "20%" }}
              className="img-fluid mx-auto d-block"
              src={require("../img/natural1_1.png")}
              alt="natural.jpg"
            ></img>
            <h3 style={{ textAlign: "center" }}>Ingredientes Naturales:</h3>
            Creemos en aprovechar el poder de la naturaleza para mejorar la
            salud y vitalidad de tu piel. Nuestros productos están enriquecidos
            con una mezcla seleccionada de ingredientes naturales conocidos por
            sus propiedades beneficiosas. Desde extractos botánicos y aceites
            nutritivos hasta potentes antioxidantes y vitaminas, nuestras
            formulaciones están diseñadas para trabajar en armonía con tu piel.
          </Col>
          <Col sm={12} md={4} lg={4} xl={4} className="mb-10 mb-lg-0">
            <img
              style={{ width: "50%", borderRadius: "20%" }}
              className="img-fluid mx-auto d-block"
              src={require("../img/woman1_1.png")}
              alt="natural.jpg"
            ></img>
            <h3 style={{ textAlign: "center" }}>
              Resultados<br></br> Visibles:
            </h3>
            En el corazón de nuestra línea de productos está el compromiso de
            entregar resultados visibles. Entendemos que deseas productos de
            cuidado de la piel que realmente marquen una diferencia, y es por
            eso que nuestras formulaciones son desarrolladas pensando en la
            eficacia. Con el uso regular, nuestros productos te ayudarán a
            lograr mejoras notables en la textura, tono y apariencia de tu piel.
          </Col>
        </Row>
      </div>
      <hr></hr>
    </div>
  );
};

export default Header;
