import React, { useRef } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
//call action = useDispatch
//bring something from state = useSelector

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const windowWidth = useRef(window.innerWidth);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div>
      <div className="container position-relative">
        {windowWidth.current > 600 ? (
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 pt-24">
              <div className="mw-xl mw-lg-2xl mx-auto mx-lg-0">
                {/* <a className="btn d-inline-flex px-2 py-1 mb-6 align-items-center btn-success-light rounded-pill" href="#">
                <span className="me-1 fw-semibold">
                  👋 We are hiring. View open roles
                </span>
                <svg
                  width="15"
                  height="15"
                  viewbox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.66667 3.41675L12.75 7.50008M12.75 7.50008L8.66667 11.5834M12.75 7.50008L2.25 7.50008"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </a> */}
                <h1 style={{ fontSize: "3rem" }} className="h1 mb-6">
                  Cuidado Para La Piel: ¡Descubre tu Resplandor Natural!
                </h1>
                <p style={{ fontSize: "1.5rem" }} className="mw-lg fs-7 mb-10">
                Experimenta lo último en excelencia en el cuidado de la piel. Diseñado para nutrir, rejuvenecer y transformar tu piel.
                </p>
                {/* <div className="d-sm-flex align-items-center mb-24">
                <a
                  className="btn d-block d-sm-inline-block mb-4 mb-sm-0 me-sm-6 btn-primary"
                  href="#"
                >
                  Join Free for 30 Days
                </a>
                <a
                  className="btn d-block d-sm-inline-block btn-outline-light-dark"
                  href="#"
                >
                  <svg
                    width="19"
                    height="18"
                    viewbox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.75 3.75C2.75 2.92157 3.42157 2.25 4.25 2.25H6.70943C7.03225 2.25 7.31886 2.45657 7.42094 2.76283L8.5443 6.13291C8.66233 6.48699 8.50203 6.87398 8.1682 7.0409L6.47525 7.88737C7.30194 9.72091 8.77909 11.1981 10.6126 12.0247L11.4591 10.3318C11.626 9.99796 12.013 9.83767 12.3671 9.9557L15.7372 11.0791C16.0434 11.1811 16.25 11.4677 16.25 11.7906V14.25C16.25 15.0784 15.5784 15.75 14.75 15.75H14C7.7868 15.75 2.75 10.7132 2.75 4.5V3.75Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <span className="ms-1 text-black fw-medium">Book a call</span>
                </a>
              </div> */}
                {/* <p className="fs-8 fw-semibold text-secondary mb-6">
                TRUSTED AND LOVED BY 100+ TECH FIRST TEAMS
              </p> */}
                {/* <div className="d-flex flex-column flex-sm-row align-items-center">
                <img
                  className="mb-5 mb-sm-0 me-sm-6"
                  src="flaro-assets/logos/brands/brand.png"
                  alt=""
                />
                <img
                  className="mb-5 mb-sm-0 me-sm-6"
                  src="flaro-assets/logos/brands/brand2.png"
                  alt=""
                />
                <img src="flaro-assets/logos/brands/brand3.png" alt="" />
              </div> */}
              </div>
            </div>
            <div className="col-12 col-lg-6 pt-24">
              <div className="mw-2xl mx-auto">
                <img
                  style={{ borderRadius: "20%" }}
                  className="img-fluid"
                  src={require("../img/hadacy.png")}
                  alt="hero.jpg"
                />
                {}
              </div>
            </div>
          </div>
        ) : (
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 pt-24">
              <div className="mw-2xl mx-auto">
                <img
                  style={{ borderRadius: "20%" }}
                  className="img-fluid"
                  src={require("../img/hadacy.png")}
                  alt="hero.jpg"
                />
                {}
              </div>
            </div>
            <div className="col-12 col-lg-6 pt-24">
              <div className="mw-xl mw-lg-2xl mx-auto mx-lg-0">
                <h1 style={{ fontSize: "2rem" }} className="h1 mb-6">
                Cuidado Para La Piel: ¡Descubre tu Resplandor Natural!
                </h1>
                <p style={{ fontSize: "1rem" }} className="mw-lg fs-7 mb-10">
                Experimenta lo último en excelencia en el cuidado de la piel. Diseñado para nutrir, rejuvenecer y transformar tu piel.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
