import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const CkeckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <div className="row justify-content-center mb-4">
      <Nav.Item>
        {step1 ? (
          <Nav.Link as={Link} to="/login">
            Sign In
          </Nav.Link>
        ) : (
          <Nav.Link disabled>Sign In</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <Nav.Link as={Link} to="/shipping">
            Envío
          </Nav.Link>
        ) : (
          <Nav.Link disabled>Envío</Nav.Link>
        )}
      </Nav.Item>

      {/* <Nav.Item>
        {step3 ? (
          <Nav.Link as={Link} to="/payment">
            Payment
          </Nav.Link>
        ) : (
          <Nav.Link disabled>Payment</Nav.Link>
        )}
      </Nav.Item> */}

      <Nav.Item>
        {step4 ? (
          <Nav.Link as={Link} to="/placeorder">
            Realizar Pedido
          </Nav.Link>
        ) : (
          <Nav.Link disabled>Realizar Pedido</Nav.Link>
        )}
      </Nav.Item>
    </div>
  );
};

export default CkeckoutSteps;
