import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import SearchBox from "./SearchBox";
import { useNavigate } from "react-router-dom";
//call action = useDispatch
//bring something from state = useSelector

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <header>
      <Navbar bg="light" variant="light" expand="lg" collapseOnSelect>
        <Container>
          <Nav.Link as={Link} to="/">
            <Navbar.Brand>HADACY</Navbar.Brand>
          </Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div>
              <Routes>
                <Route path="/" element={<SearchBox></SearchBox>}></Route>
                <Route path="/search/*" element={<SearchBox></SearchBox>}></Route>
                <Route path="/page/*" element={<SearchBox></SearchBox>}></Route>
              </Routes>
            </div>
            <Nav className="ml-auto">
              <Nav.Link state={{ color: "black" }} as={Link} to="/cart">
                <i className="fas fa-shopping-cart"></i> Carrito
              </Nav.Link>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <Nav.Link as={Link} to="/profile" style={{ color: "black" }}>
                    {/* <i className="fas fa-user"></i> {userInfo.name} */}
                    Perfil
                  </Nav.Link>
                  <Nav.Link onClick={logoutHandler} style={{ color: "black" }}>
                    Logout
                  </Nav.Link>
                </NavDropdown>
              ) : (
                <Nav.Link as={Link} to="/login">
                  <i className="fas fa-user"></i> Iniciar Sesión
                </Nav.Link>
              )}

              {userInfo && userInfo.isAdmin && (
                <NavDropdown
                  title="Admin"
                  id="adminmenu"
                  style={{ color: "black" }}
                >
                  <Nav.Link
                    as={Link}
                    to="/admin/userlist"
                    style={{ color: "black" }}
                  >
                    Users
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/admin/productlist"
                    style={{ color: "black" }}
                  >
                    Products
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/admin/orderlist"
                    style={{ color: "black" }}
                  >
                    Orders
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/admin/visitors"
                    style={{ color: "black" }}
                  >
                    Visitors
                  </Nav.Link>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Row>
          <Col>
            <img
              className="img-fluid float-right ml-3"
              src={require("../img/Hada1_1.png")}
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
              alt="hero.jpg"
            />
          </Col>
        </Row>
      </Navbar>
    </header>
  );
};

export default Header;
