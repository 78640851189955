import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CkeckoutSteps";

const ShippingScreen = ({ history }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  let [address, setAddress] = useState(shippingAddress.address);
  let [city, setCity] = useState(shippingAddress.city);
  let [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  let [tel, setTel] = useState(shippingAddress.tel);
  let [country, setCountry] = useState(shippingAddress.country);
  let [state, setState] = useState(shippingAddress.state);

  const submitHabdler = (e) => {
    e.preventDefault();
    country = "México";
    dispatch(saveShippingAddress({ address, city,state, postalCode, tel, country }));
    navigate("/placeorder");
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2></CheckoutSteps>
      <h1>Shipping</h1>
      <Form onSubmit={submitHabdler}>
        <Form.Group controlId="address">
          <Form.Label>Dirección (Calle, Colonia y Número) </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="city">
          <Form.Label>Ciudad</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingresa tu Ciudad"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="state">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingresa tu Estado"
            value={state}
            required
            onChange={(e) => setState(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="postalCode">
          <Form.Label>Código Postal</Form.Label>
          <Form.Control
            type="Number"
            placeholder="Ingresa tu Código Postal"
            value={postalCode}
            required
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="postalCode">
          <Form.Label>Teléfono de Contacto</Form.Label>
          <Form.Control
            type="Number"
            maxLength={10}
            placeholder="Ingresa tu Teléfono de Contacto"
            value={tel}
            required
            //Math.max(0, parseInt(e.target.value) ).toString().slice(0,16)
            onChange={(e) => setTel(e.target.value.slice(0, 10))}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="country">
          <Form.Label>País</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingresa tu País"
            //value={country}
            value={"México"}
            required
            disabled
            //onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <br></br>
        <p>
          Por el momento solo vendemos nuestros productos en México, Si deseas
          comprar desde otro país, por favor contáctanos{" "}
        </p>
        <Button type="submit" variant="primary" disabled={ tel && postalCode && state && city && address ? false : true }  >
          Continue
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ShippingScreen;
