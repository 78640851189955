import {
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
} from "../constants/productConstans";
import axios from "axios";

// Create a thunk action creator called listProducts, which accepts no arguments and returns an async function
export const listProducts = (keyword = '', pageNumber='' ) => async (dispatch) => {
  // The body of the function will be a try/catch block
  try {
    // The first thing we do is dispatch PRODUCT_LIST_REQUEST, which will set loading to true
    dispatch({ type: PRODUCT_LIST_REQUEST });

    // Then we make a request to the API endpoint for products
    const { data } = await axios.get(`/api/products?keyword=${keyword}&pageNumber=${pageNumber}`);

    // If the request is successful, we dispatch PRODUCT_LIST_SUCCESS, which will set the products array to the data from the API, and loading to false
    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // If the request fails, we dispatch PRODUCT_LIST_FAIL, which will set the error message to the message from the API, or a generic error message if there is no message, and loading to false
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// 1. Send a request to the server to get product details
export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get("/api/products/" + id);

    // 2. If the request is successful, dispatch PRODUCT_DETAILS_SUCCESS and pass the data to the reducer
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // 3. If the request is unsuccessful, dispatch PRODUCT_DETAILS_FAIL and pass the error message to the reducer
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    // 1. Get the user info from the state
    const {
      userLogin: { userInfo },
    } = getState();

    // 2. Send a request to the server to delete the product
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // 3. If the request is successful, dispatch PRODUCT_DELETE_SUCCESS and pass the data to the reducer
    await axios.delete(`/api/products/${id}`, config);

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    });
  } catch (error) {
    // 4. If the request is unsuccessful, dispatch PRODUCT_DELETE_FAIL and pass the error message to the reducer
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    });

    // 1. Get the user info from the state
    const {
      userLogin: { userInfo },
    } = getState();

    // 2. Send a request to the server to create the product
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // 3. If the request is successful, dispatch PRODUCT_CREATE_SUCCESS and pass the data to the reducer
    const { data } = await axios.post(`/api/products`, {}, config);

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // 4. If the request is unsuccessful, dispatch PRODUCT_CREATE_FAIL and pass the error message to the reducer
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });

    // 1. Get the user info from the state
    const {
      userLogin: { userInfo },
    } = getState();

    // 2. Send a request to the server to update the product
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // 3. If the request is successful, dispatch PRODUCT_UPDATE_SUCCESS and pass the data to the reducer
    const { data } = await axios.put(
      `/api/products/${product._id}`,
      product,
      config
    );

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // 4. If the request is unsuccessful, dispatch PRODUCT_UPDATE_FAIL and pass the error message to the reducer
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProductReview = (productId, review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_REQUEST,
    });

    // 1. Get the user info from the state
    const {
      userLogin: { userInfo },
    } = getState();

    // 2. Send a request to the server to create the product review
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // 3. If the request is successful, dispatch PRODUCT_CREATE_REVIEW_SUCCESS and pass the data to the reducer
    await axios.post(`/api/products/${productId}/reviews`, review, config);

    dispatch({
      type: PRODUCT_CREATE_REVIEW_SUCCESS,
    });
  } catch (error) {
    // 4. If the request is unsuccessful, dispatch PRODUCT_CREATE_REVIEW_FAIL and pass the error message to the reducer
    dispatch({
      type: PRODUCT_CREATE_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
