import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { resetPasswordRequest } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import { useNavigate } from "react-router-dom";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userReset = useSelector((state) => state.userPasswordReset);
  const { loading, error, success } = userReset;

  const submitHandler = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(resetPasswordRequest(email));
    } else {
      setMessage("No pudimos encontrar tu correo electrónico. Intenta de nuevo.");
    }
  };

  useEffect(() => {
    if (success) {
      setMessage("Hemos enviado un correo electrónico con instrucciones para cambiar tu contraseña. Revisa tu bandeja de entrada o spam.");
    //   setTimeout(() => {
    //     navigate("/login");
    //   }, 5000); // Redirect to login after 5 seconds
    }
  }, [navigate, success]);

  return (
    <FormContainer>
      <h1>Cambiar Contraseña </h1>
      {message && <Message variant="info">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>Correo Electrónico </Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingresa tu correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary">
          Cambiar
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Recordaste tu contraseña ?{" "}
          <Link to="/login">
          Iniciar sesión
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default ForgotPasswordScreen;
