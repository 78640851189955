import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listOrders } from "../actions/orderActions";
import Paginate from "../components/Paginate";
import { set } from "mongoose";

const OrderListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pageNumber } = useParams() || 1;

  let [isEnabledPaid, setIsEnabledPaid] = useState(false);
  let [isEnabledDelivered, setIsEnabledDelivered] = useState(false);
  let [userSearchTxt, setUserSearch] = useState("");

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders, page, orderPages } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (
      userInfo &&
      userInfo.isAdmin &&
      (isEnabledPaid == true || isEnabledDelivered == true)
    ) {
      dispatch(listOrders(pageNumber, isEnabledPaid, isEnabledDelivered));
    }
    if (
      userInfo &&
      userInfo.isAdmin &&
      isEnabledPaid == false &&
      isEnabledDelivered == false
    ) {
      if(userSearchTxt === "") {
      dispatch(listOrders(pageNumber,false,false,"",""));
      } else {
        dispatch(listOrders(pageNumber,false,false,"",userSearchTxt));
      }
    } else if (userInfo && !userInfo.isAdmin) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    pageNumber,
    isEnabledPaid,
    isEnabledDelivered,
    userSearchTxt,
  ]);

  //console.log(orders);

  const orderSearch = (e) => {
    setIsEnabledPaid(false);
    setIsEnabledDelivered(false);
    setUserSearch("");
    if (e.target.value === "") {
      dispatch(listOrders(pageNumber));
    } else if (e.target.value.length > 23) {
      dispatch(listOrders(1, false, false, e.target.value));
    }
  };

  const userSearch = (e) => {
    setIsEnabledPaid(false);
    setIsEnabledDelivered(false);
    setUserSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(listOrders(pageNumber));
    } else if (e.target.value.length > 6) {
      dispatch(listOrders(pageNumber, false, false, "", userSearchTxt));
    }
  };

  return (
    <>
      <h1>Orders</h1>
      <Row className="align-items-center" style={{ marginBottom: "1rem" }}>
        <Col className="text-center">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefaultPaid"
              checked={isEnabledPaid}
              onChange={(e) => {
                setIsEnabledPaid(e.target.checked);
              }}
            ></input>
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Paid
            </label>
          </div>
        </Col>
        <Col className="text-center">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefaultDelivered"
              checked={isEnabledDelivered}
              onChange={(e) => {
                setIsEnabledDelivered(e.target.checked);
              }}
            ></input>
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Delivered
            </label>
          </div>
        </Col>
        <Col className="text-center">
          <Form.Control
            type="email"
            placeholder="User Email/Name Search"
            onChange={(e) => {
              userSearch(e);
            }}
          />
        </Col>
        <Col className="text-center">
          <Form.Control
            type="email"
            placeholder="Order id search"
            onChange={(e) => {
              orderSearch(e);
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : orders ? (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>USER</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.user && order.user.name}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>${order.totalPrice}</td>

                  <td>
                    {order.isPaid ? (
                      order.paidAt.substring(0, 10)
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>

                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>

                  <td>
                    <Link to={`/order/${order._id}`}>
                      <Button variant="light" className="btn-sm">
                        Details
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={orderPages}
            page={page}
            isAdmin={true}
            isOrder={true}
          ></Paginate>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default OrderListScreen;
