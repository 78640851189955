import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import {
  Row,
  Form,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import { set } from "mongoose";

let initialOptions = {
  "client-id": "",
  currency: "MXN",
  intent: "capture",
};

// const customization = {
//  texts: {
//    action: 'buy',
//    valueProp: 'security_details',
//  },
//  visual: {
//      buttonBackground: 'black',
//      borderRadius: '6px',
//      buttonHeight: "48px",
//  },
// }

const OrderScreen = ({ match }) => {
  const windowWidth = useRef(window.innerWidth);
  //console.log("windowWidth: " + windowWidth.current);
  const navigate = useNavigate();
  const orderId = useParams().id;
  const [sdkReady, setSdkReady] = useState(false);
  //const orderId = match.params.id;
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const [preferenceId, setPreferenceId] = useState(null);
  const [shippingCompany, setShippingCompany] = useState(null);
  const [shippingNumber, setShippingNumber] = useState(null);

  if (process.env.REACT_APP_NODE_ENV === "production") {
    initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY_PROD);
  } else {
    initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);
  }

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    success: successDeliver,
    error: errorDeliver,
  } = orderDeliver;

  //console.log("order");
  //console.log(order);
  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    const createPreference = async () => {
      let items = [
        {
          title: "Productos Hadacy",
          unit_price: order.totalPrice,
          quantity: 1,
          currency_id: "MXN",
          description: "Orden: " + order._id,
        },
      ];

      let payer = {
        name: userInfo.name,
        email: userInfo.email,
      };
      // await order.orderItems.forEach((item) => {
      //   items.push({
      //     title: item.name,
      //     unit_price: item.price,
      //     quantity: item.qty,
      //     currency_id: "MXN",
      //   });
      // });

      try {
        const response = await axios.post(
          "/api/mercadopago",
          {
            items,
            external_reference: orderId,
            payer,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        const { id } = response.data;
        return id;
      } catch (error) {
        console.log(error);
      }
    };

    const handleBuy = async () => {
      const id = await createPreference();
      if (id) {
        setPreferenceId(id);
      }
    };
    const addPayPalScript = async () => {
      //const { data: clientId } = await axios.get("/api/config/paypal");
      // const script = document.createElement("script");
      // script.type = "text/javascript";
      // script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=MXN`;
      // script.async = true;
      // script.onload = () => {
      //   setSdkReady(true);
      // };
      // document.body.appendChild(script);
      // console.log("clientId");
      // console.log(clientId);

      let data = await axios.get("/api/config/paypal");
      initialOptions["client-id"] = data.data;
      //console.log("initialOptions");
      //console.log(initialOptions);
      setSdkReady(true);
    };

    if (!order || successPay || successDeliver) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!sdkReady) {
        addPayPalScript();
      }
      if (!preferenceId) {
        handleBuy();
      }
      // else {
      //   setSdkReady(true);
      // }
    }

    if (!order || order._id !== orderId) {
      dispatch(getOrderDetails(orderId));
    }
  }, [
    dispatch,
    orderId,
    order,
    successPay,
    successDeliver,
    preferenceId,
    userInfo,
    navigate,
    sdkReady,
  ]);

  const successPaymentHandler = (paymentResult) => {
    //console.log(paymentResult);
    dispatch(payOrder(orderId, paymentResult));
  };

  const deliverHandler = () => {
    //console.log("deliverHandler");
    dispatch(deliverOrder(order, shippingCompany, shippingNumber));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1 style={{ fontSize: "1rem" }}>Orden {order._id} </h1>

      <Row>
        <Col md={windowWidth.current > 950 ? 8 : 12}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <strong>Nombre:</strong> {order.user.name}
              <br></br>
              <strong>Email: </strong>{" "}
              <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              <p>
                <strong>Dirección:</strong>
                {order.shippingAddress.address},{order.shippingAddress.city} ,{" "}
                {order.shippingAddress.state},{" "}
                {order.shippingAddress.postalCode},
                {order.shippingAddress.country}
                <br></br>
                <strong>Teléfono:</strong>
                {order.shippingAddress.tel}
                <br></br>
              </p>
              {order.isPaid ? (
                <b>
                  <p>
                    Referencia de Pago:{" "}
                    {order.paymentResultMercadoPago.collection_id}
                  </p>
                </b>
              ) : (
                <p></p>
              )}
              {order.isDelivered ? (
                <p>Enviado por: {order.shippingCompany} con número de rastreo: {order.shippingNumber}</p>
              ) : (
                <p></p>
              )}
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered on{" "}
                  {DateTime.fromISO(order.deliveredAt).toLocaleString(
                    DateTime.DATETIME_MED
                  )}
                </Message>
              ) : (
                <Message variant="danger">Sin Enviar</Message>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Método de Pago</h2>
              <p>
                <strong>Método:</strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">
                  Pagado el{" "}
                  {DateTime.fromISO(order.paidAt).toLocaleString(
                    DateTime.DATETIME_MED
                  )}
                </Message>
              ) : (
                <Message variant="danger">Sin Pagar</Message>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Productos</h2>
              {order.orderItems.length === 0 ? (
                <Message>Your order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={3}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          ></Image>
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={windowWidth.current > 950 ? 4 : 12}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Resumen</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Productos</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Costo Envío </Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Impuestos (Transacción En Línea)</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {/* <Row>
                  <Col>Total</Col>
                  <Col>${order.totalPrice}</Col>
                </Row> */}
                <Row>
                  <Col>Total</Col>
                  {userInfo && userInfo.isAdmin ? (
                    <Col className="text-right text-warning ">
                      Admin Discount
                    </Col>
                  ) : (
                    <div></div>
                  )}

                  {order.coupon !== null && order.coupon !== undefined ? (
                    <Row>
                      <Row>
                        <Col className="text-right  text-danger text-decoration-line-through ">${order.coupon.beforeDiscount}</Col>
                      </Row>
                      <Row>
                        <Col className="text-right">${order.totalPrice}</Col>
                      </Row>
                      <Row>
                        <Col className="text-success ">
                        {order.coupon.Name} {order.coupon.Discount}% OFF
                        </Col>
                      </Row>
                    </Row>
                  ) : (
                    <Col className="text-right">${order.totalPrice}</Col>
                  )}

                  {/* <Col className="text-right">${cart.totalPrice}</Col> */}
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <>
                      <Row>
                        <Col>
                          {/* <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                              style={{ layout: "horizontal" }}
                              createOrder={(data, actions) => {
                                return actions.order.create({
                                  purchase_units: [
                                    {
                                      amount: {
                                        value: order.totalPrice,
                                      },
                                    },
                                  ],
                                });
                              }}
                              onApprove={successPaymentHandler}
                              onError={(err) => {
                                console.log(err);
                              }}
                            />
                          </PayPalScriptProvider> */}
                        </Col>
                      </Row>
                    </>
                  )}
                  {!preferenceId ? (
                    <Loader />
                  ) : (
                    <Row>
                      <Col>
                        {userInfo && (
                          /*userInfo.isAdmin &&*/ <Wallet
                            className="btn btn-block"
                            initialization={{ preferenceId }}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <h2>Enviar Orden</h2>
                    <p>
                      <strong>Compañía de Envío: </strong>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese Compañía de Envío"
                        value={shippingCompany}
                        onChange={(e) => setShippingCompany(e.target.value)}
                      ></Form.Control>
                    </p>
                    <p>
                      <strong>Número de Rastreo: </strong>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese Número de Rastreo"
                        value={shippingNumber}
                        onChange={(e) => setShippingNumber(e.target.value)}
                      ></Form.Control>
                    </p>
                    {/* <Button
                      type="button"
                      className="btn btn-block"
                      onClick={() =>
                        dispatch(
                          deliverOrder(orderId, shippingCompany, shippingNumber)
                        )
                      }
                    >
                      Marcar como Enviado
                    </Button> */}
                    <Button
                      type="button"
                      className="btn btn-block"
                      onClick={deliverHandler}
                      disabled={
                        shippingNumber && shippingCompany ? false : true
                      }
                    >
                      Marcar como Enviado
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
