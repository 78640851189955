import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listProductDetails, updateProduct } from "../actions/productActions";
import FormContainer from "../components/FormContainer";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstans";
import axios from "axios";

const ProdcuctEditScreen = ({ history }) => {
  const { id } = useParams();
  const productId = id;

  const [uploadFile, setUploadFile] = useState("");
  const [cloudinaryImage, setCloudinaryImage] = useState("");

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  let [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState("");
  const [description, setDescription] = useState(0);
  const [ingredients, setIngredients] = useState("");
  const [modeOfUse, setModeOfUse] = useState("");
  const [uploading, setUploading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const productDetail = useSelector((state) => state.productDetail);
  const { loading, error, product } = productDetail;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      navigate("/admin/productList");
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product.name);
        setPrice(product.price);
        setImage(product.image);
        setBrand(product.brand);
        setCategory(product.category);
        setCountInStock(product.countInStock);
        setDescription(product.description);
        setIngredients(product.ingredients);
        setModeOfUse(product.modeOfUse);
      }
    }
  }, [dispatch, product, productId, successUpdate, navigate]);

  const uploadFileHandler = async (e) => {
    //const file = e.target.files[0];
    //const formData = new FormData();
    //console.log(file);
    //formData.append("image", file);

    e.preventDefault();
    setUploading(true);
    try {
      // const config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      // const { data } = await axios.post("/api/upload", formData, config);
      //console.log(data);

      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("upload_preset", "HADACY");
      //console.log("FORMDATA");
      //console.log(formData);
      return await axios.post(
        "https://api.cloudinary.com/v1_1/cc1991/image/upload",
        formData
      )
        .then((response) => {
          //console.log("RESPONSE");
          //console.log(response);
          setCloudinaryImage(response.data.secure_url);
          setImage(response.data.url);
          setUploading(false);
          return response.data.url;
        })
        .catch((error) => {
          console.log("ERROR");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setUploading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    image = uploadFile != "" ? await uploadFileHandler(e) : image;
    //console.log("IMAGE URL");
    //console.log(image);
    // UPDATE PRODUCT
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        image,
        brand,
        category,
        description,
        ingredients,
        modeOfUse,
        countInStock,
      })
    );
    dispatch(listProductDetails(productId));
  };

  return (
    <>
      <Link to="/admin/productList" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader></Loader>}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Image URL"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="Choose File"
                custom
                onChange ={(event) => {setUploadFile(event.target.files[0]);}} 
              ></Form.Control>
              {uploading && <Loader></Loader>}
            </Form.Group>
            <Form.Group controlId="brand">
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Brand"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="countInStock">
              <Form.Label>Count in Stock</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Count in Stock"
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea" rows={6}
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="ingredients">
              <Form.Label>Ingredients</Form.Label>
              <Form.Control
                type="text"
                as="textarea" rows={6}
                placeholder="Enter Ingredients"
                value={ingredients}
                onChange={(e) => setIngredients(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="modeOfUse">
              <Form.Label>Mode of Use</Form.Label>
              <Form.Control
                type="text"
                as="textarea" rows={6}
                placeholder="Enter Mode of Use"
                value={modeOfUse}
                onChange={(e) => setModeOfUse(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProdcuctEditScreen;
