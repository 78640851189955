import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import UserConfirmationScreen from "./screens/userRegistrationConfirmation";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import ErrorScreen from "./screens/ErrorScreen";
import VisitorsScreen from "./screens/VisitorsScreen";
import UsScreen from "./screens/UsScreen";
import hadacyImage from "./favicon.ico";
import ForgotPasswordScreen from "./screens/ForgotPassword";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  return (
    <Router>
      <Header></Header>
      <FloatingWhatsApp
        avatar={hadacyImage}
        allowClickAway="true"
        phoneNumber="523329183482"
        accountName="HADACY"
        darkMode="true"
        chatMessage="Hola, ¿En qué podemos ayudarte?"
      />
      <main className="py-3" style={{ backgroundColor: "#FFFF" }}>
        <Container style={{ minHeight: "86vh" }}>
          <Routes>
            <Route
              path="/order/:id"
              element={<OrderScreen></OrderScreen>}
            ></Route>
            <Route
              path="/placeorder"
              element={<PlaceOrderScreen></PlaceOrderScreen>}
            ></Route>
            <Route
              path="/shipping"
              element={<ShippingScreen></ShippingScreen>}
            ></Route>
            <Route
              path="/payment"
              element={<PaymentScreen></PaymentScreen>}
            ></Route>
            <Route path="/login" element={<LoginScreen></LoginScreen>}></Route>
            <Route
              path="/profile"
              element={<ProfileScreen></ProfileScreen>}
              exact
            ></Route>
            <Route
              path="/profile/:pageNumber"
              element={<ProfileScreen></ProfileScreen>}
              exact
            ></Route>
            <Route
              path="/register"
              element={<RegisterScreen></RegisterScreen>}
            ></Route>
            <Route
              path="/confirm/:id"
              element={<UserConfirmationScreen></UserConfirmationScreen>}
            ></Route>
            <Route
              path="/product/:id"
              element={<ProductScreen></ProductScreen>}
            ></Route>
            <Route
              path="/cart/:id?"
              element={<CartScreen></CartScreen>}
            ></Route>
            <Route path="/" element={<HomeScreen></HomeScreen>} exact></Route>
            <Route
              path="/page/:pageNumber"
              element={<HomeScreen></HomeScreen>}
              exact
            ></Route>
            <Route
              path="/search/:keyword/page/:pageNumber"
              element={<HomeScreen></HomeScreen>}
              exact
            ></Route>
            <Route
              path="/search/:keyword"
              element={<HomeScreen></HomeScreen>}
              exact
            ></Route>
            <Route
              path="/admin/userlist"
              element={<UserListScreen></UserListScreen>}
              exact
            ></Route>
            <Route
              path="/admin/userlist/:pageNumber"
              element={<UserListScreen></UserListScreen>}
              exact
            ></Route>
            <Route
              path="/admin/productlist"
              element={<ProductListScreen></ProductListScreen>}
              exact
            ></Route>
            <Route
              path="/admin/productlist/:pageNumber"
              element={<ProductListScreen></ProductListScreen>}
              exact
            ></Route>
            <Route
              path="/admin/orderlist"
              element={<OrderListScreen></OrderListScreen>}
              exact
            ></Route>
            <Route
              path="/admin/orderlist/:pageNumber"
              element={<OrderListScreen></OrderListScreen>}
              exact
            ></Route>
            <Route
              path="/admin/product/:id/edit"
              element={<ProductEditScreen></ProductEditScreen>}
            ></Route>
            <Route
              path="/admin/user/:id/edit"
              element={<UserEditScreen></UserEditScreen>}
            ></Route>
            <Route
              path="/admin/visitors"
              element={<VisitorsScreen></VisitorsScreen>}
              exact
            ></Route>
            <Route
              path="/us"
              element={<UsScreen></UsScreen>}
              exact
            ></Route>
            <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
            <Route path="*" element={<ErrorScreen />} />
          </Routes>
        </Container>
      </main>
      <Footer></Footer>
    </Router>
  );
};

//https://cdnjs.com/

// <!-- <script
//   defer="defer"
//   src="/frontend/build/static/js/main.5be236b1.js"
// ></script>
// <link
//   href="/frontend/build/static/css/main.f129a816.css"
//   rel="stylesheet"
// /> -->

export default App;
