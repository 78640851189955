import React from "react";
import { Pagination, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Paginate = ({
  pages,
  page,
  isAdmin = false,
  keyword = "",
  isOrder = false,
  isUserList = false,
  isOrderProfile = false,
}) => {
  return (
    pages > 1 && (
      <Pagination className="justify-content-center">
        {/* <Pagination.First /> */}
        <Nav.Link
          as={Link}
          key={0}
          to={
            isOrderProfile
              ? `/profile/1`
              : !isAdmin
              ? keyword
                ? `/search/${keyword}/page/1`
                : `/page/1`
              : isOrder
              ? `/admin/orderlist/1`
              : isUserList
              ? `/admin/userlist/1`
              : `/admin/productlist/1`
          }
        >
          Primero
        </Nav.Link>
        {[...Array(pages).keys()].map((x) => (
          <Nav.Link
            as={Link}
            key={x + 1}
            to={
              isOrderProfile
                ? `/profile/${x + 1}`
                : !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/${x + 1}`
                  : `/page/${x + 1}`
                : isOrder
                ? `/admin/orderlist/${x + 1}`
                : isUserList
                ? `/admin/userlist/${x + 1}`
                : `/admin/productlist/${x + 1}`
            }
            active={x + 1 === page}
            className={
              x + 1 === page ? "bg-dark text-white rounded" : ".bg-white"
            }
          >
            {x + 1}
          </Nav.Link>
        ))}
        <Nav.Link
          as={Link}
          key={pages}
          to={
            isOrderProfile
              ? `/profile/${pages}`
              : !isAdmin
              ? keyword
                ? `/search/${keyword}/page/` + pages
                : `/page/` + pages
              : isOrder
              ? `/admin/orderlist/` + pages
              : isUserList
              ? `/admin/userlist/` + pages
              : `/admin/productlist/` + pages
          }
        >
          Último
        </Nav.Link>
        {/* <Pagination.Last /> */}
      </Pagination>
    )
  );
};

export default Paginate;
