import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col ,InputGroup} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { login } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import {useLocation,useNavigate } from "react-router-dom";

const LoginScreen = ({history}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [errorMensaje, setErrorMensaje] = useState(null);

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo && userInfo.confirmed === true ) {
      navigate(redirect);
    }else if(userInfo && userInfo.confirmed === false){
      setErrorMensaje('Por favor confirma tu correo electrónico para poder ingresar. Revisa tu bandeja de entrada o correo no deseado.');

    }
  }, [navigate, userInfo, redirect,errorMensaje]);

  const submitHandler = (e) => {
    e.preventDefault();
    //DISPATCH LOGIN
    dispatch(login(email, password,));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <FormContainer>
      <h1>Iniciar sesión</h1>
      {error && <Message variant="danger">{error}</Message>}
      {errorMensaje && <Message variant="danger">{errorMensaje}</Message>}
      {loading && <Loader></Loader>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>Dirección de correo electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingresa tu correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Contraseña</Form.Label>
          <InputGroup>
            <Form.Control
              type={passwordVisible ? "text" : "password"}
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
              <Button variant="" onClick={togglePasswordVisibility}>
                <i className={passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"}></i>
              </Button>
          </InputGroup>
        </Form.Group>
        <Button type="submit" variant="primary">
        Iniciar sesión
        </Button>
      </Form>

        <Row className="py-3">
            <Col>
                ¿Nuevo cliente?{' '}
                <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                  Regístrate
                </Link>
            </Col>
        </Row>
        <Row className="py-3">
            <Col>
                <Link to="/forgotpassword">¿Olvidaste tu contraseña?</Link>
            </Col>
        </Row>

    </FormContainer>
  );
};

export default LoginScreen;
