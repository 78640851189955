import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CkeckoutSteps";
import Message from "../components/Message";
import { Link } from "react-router-dom";
import { createOrder } from "../actions/orderActions";
import axios from "axios";
import { set } from "mongoose";

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const navigate = useNavigate();

  // Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const calculateMPOrPaypalTax = (itemsPrice, platform) => {
    if (platform === "MP") {
      let mpPorcentage = 0.0379;
      let mpFixed = 4.0;
      let mpTax = mpPorcentage * itemsPrice + mpFixed; //4.379 ... item in 10
      let mpTaxIva = mpTax * 0.21; // 0.70064
      let mpTaxTotal = mpTax + mpTaxIva; //5.07964
      //console.log("mpTax:"+mpTax);
      //console.log("mpTaxTotal:"+mpTaxTotal);
      return mpTaxTotal;
    } else if (platform === "Paypal") {
      return 0.15 * itemsPrice;
    } else {
      return 0;
    }
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 1500 ? 0 : 150);
  //cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
  cart.taxPrice = addDecimals(
    Number(calculateMPOrPaypalTax(cart.itemsPrice, "MP"))
  );
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  if (userInfo && userInfo.isAdmin) {
    cart.totalPrice = 5;
  }

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  const [coupon, setCoupon] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [totalPriceDiscount, setTotalPriceDiscount] = useState(0);

  useEffect(() => {
    if (success) {
      //console.log("order");
      //console.log(order);
      navigate(`/order/${order._id}`);
      orderCreate.success = false;
    }
  }, [navigate, success, order, couponMessage, totalPriceDiscount]);

  const checkCoupon = async (coupon) => {
    //console.log(coupon);
    //get all coupons from backend in porcentage

    let couponBd = await axios.post("/api/coupons", { coupon });
    console.log(couponBd.data);
    if (couponBd.data.coupon && couponBd.data.coupon.Active === true) {
      cart.coupon = couponBd.data.coupon;
      cart.coupon.beforeDiscount = cart.totalPrice;
      cart.totalPrice = (
        Number(cart.totalPrice) -
        Number(cart.totalPrice * (couponBd.data.coupon.Discount / 100))
      ).toFixed(2);
      setCouponMessage(couponBd.data.message);
      dispatch({
        type: "CART_COUPON",
        payload: cart.coupon,
      });
      setTotalPriceDiscount(cart.totalPrice);
      cart.coupon.afterDiscount = totalPriceDiscount;
    } else {
      cart.coupon = null;
      setCouponMessage(couponBd.data.message);
    }
  };

  const placeOrderHandler = () => {
    //console.log(cart.paymentMethod);
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        taxPrice: cart.taxPrice,
        shippingPrice: cart.shippingPrice,
        totalPrice:  totalPriceDiscount > 0 ? totalPriceDiscount : cart.totalPrice ,
        coupon: cart.coupon,
      })
    );
  };

  return (
    <>
      <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>

      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Dirección:</strong>
                <br></br>
                {shippingAddress.address},{shippingAddress.city} ,{" "}
                {shippingAddress.state} ,{shippingAddress.postalCode},
                {shippingAddress.country}
                <strong>
                  <br></br>Teléfono:
                </strong>
                <br></br>
                {shippingAddress.tel}
              </p>
            </ListGroup.Item>
            {/* <ListGroup.Item>
                    <h2>Payment Method</h2>
                    <strong>Method:</strong>
                    {cart.paymentMethod}
                </ListGroup.Item> */}
            <ListGroup.Item>
              <h2>Productos</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={2}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          ></Image>
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Resumen</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Productos</Col>
                  <Col className="text-right">${cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Costo Envío</Col>
                  <Col className="text-right">${cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Impuestos (Transacción En Línea)</Col>
                  <Col className="text-right">${cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  {userInfo && userInfo.isAdmin ? (
                    <Col className="text-right text-warning ">
                      Admin Discount
                    </Col>
                  ) : (
                    <div></div>
                  )}

                  {cart.coupon != null && cart.coupon != undefined && totalPriceDiscount > 0 ? (
                    <Row>
                      <Row>
                        <Col className="text-right  text-danger text-decoration-line-through">${cart.totalPrice}</Col>
                      </Row>
                      <Row>
                        <Col className="text-right">${totalPriceDiscount}</Col>
                      </Row>
                    </Row>
                  ) : (
                    <Col className="text-right">${cart.totalPrice}</Col>
                  )}

                  {/* <Col className="text-right">${cart.totalPrice}</Col> */}
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <Row>
                      <Col>Cupón de Descuento</Col>
                      <Col>
                        <Form.Control
                          type="text"
                          value={coupon}
                          onChange={(e) => {
                            setCoupon(e.target.value);
                          }}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {cart.coupon != null && cart.coupon != undefined && totalPriceDiscount > 0 ? (
                          <p className="mt-2 mb-0 text-success">
                            {couponMessage}
                            <br></br>
                            {cart.coupon.Discount}% OFF
                          </p>
                        ) : (
                          <p className="mt-2 mb-0  text-danger">
                            {couponMessage}
                          </p>
                        )}
                      </Col>
                      <Col className="mt-1">
                        <Button
                          type="button"
                          className="btn-block"
                          onClick={(e) => {
                            checkCoupon(coupon);
                          }}
                        >
                          Aplicar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {error && <Message variant="danger">{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHandler}
                >
                  Completar Orden
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlaceOrderScreen;
