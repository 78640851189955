import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

function UsScreen() {
    return (
        <Container>
            <Row className="mb-4">
                <Col className='text-center' >
                    <h2>Calidad para el cuidado de tu piel.</h2>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2>Misión</h2>
                    <p>Desarrollar y comercializar productos cosméticos para el cuidado de la piel en México que cumplan con la más alta calidad requerida para satisfacer las necesidades de nuestros clientes mediante la constante innovación y creación de fórmulas a un precio accesible.</p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2>Visión</h2>
                    <p>Posicionarse cómo una de las mejores marcas de cosméticos para el cuidado de la piel en el mercado Mexicano con productos innovadores de calidad.</p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2>Valores</h2>
                    <ListGroup>
                        <ListGroup.Item><strong><b>Honestidad:</b></strong> Comprometidos con la transparencia, nuestros productos y prácticas reflejan integridad y sinceridad.</ListGroup.Item>
                        <ListGroup.Item><strong><b>Mejora Continua:</b></strong> Estamos en constante búsqueda de formas de mejorar nuestros productos y servicios.</ListGroup.Item>
                        <ListGroup.Item><strong><b>Pasión:</b></strong> Nuestro equipo trabaja con entusiasmo y dedicación para ofrecer los mejores productos de cuidado de la piel.</ListGroup.Item>
                        <ListGroup.Item><strong><b>Diversidad:</b></strong> Valoramos y respetamos las diferentes necesidades y preferencias de nuestros clientes.</ListGroup.Item>
                        <ListGroup.Item><strong><b>Respeto:</b></strong> En cada paso, mostramos respeto por nuestros clientes, la comunidad y el medio ambiente.</ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
}

export default UsScreen;
