//import React, {useState, useEffect} from 'react';
import React, { useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Product from "../components/Product";
//import {useDispatch,useSelector} from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../actions/productActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listMyOrders } from "../actions/orderActions";
import Hero from "../components/Hero";
import ProductsVision from "../components/productsVision";
import Paginate from "../components/Paginate";
import { useParams } from "react-router-dom";
import axios from "axios";

const HomeScreen = () => {
  const { keyword } = useParams();
  const { pageNumber } = useParams() || 1;
  //console.log("pageNumber: " + pageNumber);

  //const [products,setProducts] = useState([]);
  const dispatch = useDispatch();
  const windowWidth = useRef(window.innerWidth);
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    const saveVisitor = async () => {
      try {
        const { data } = await axios.get("https://ipapi.co/json/");

        let visitor = {
          Country: data.country_name,
          Region: data.region,
          Ciudad: data.city,
        };

        const { data: visitorData } = await axios.post(
          "/api/users/visitors",
          visitor
        );
      } catch (error) {
        console.log(error);
      }
    };
    saveVisitor();

    dispatch(listProducts(keyword, pageNumber));
    dispatch(listMyOrders());
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      {/* <h1>Últimos Productos</h1> */}
      <Hero></Hero>
      <ProductsVision></ProductsVision>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h1
                    style={{ fontSize: "2rem", textAlign: "center" }}
                    className="h1 mb-6 mt-6"
                  >
                    Nuestros Productos
                  </h1>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                {products.map((product) => (
                  <Col key={product._id} sm={12} md={6} lg={4} xl={4}>
                    <Product product={product}></Product>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-5">
            <Paginate
              pages={pages}
              page={page}
              keyword={keyword ? keyword : ""}
            ></Paginate>
          </Row>
        </>
      )}
    </>
  );
};

export default HomeScreen;
