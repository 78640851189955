import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const [email, setEmail] = useState("");
  const [confirmationSaved, setConfirmationSaved] = useState(false);
  const orderId = useParams().id;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    const getUserInfo = async () => {
      // Send email to backend
      await fetch("/api/users/confirm/"+orderId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setEmail(data.email);
          setConfirmationSaved(data.confirmed);
        });
    };
    getUserInfo();
  }, [email]);

  return (
    <div>
        {email !== "" && confirmationSaved == true ? (
            <div>
                <h1>¡Gracias por registrarte!</h1>
                <a href="/login" >Ir al Login</a>
            </div>
        ) : (
            <div>
                <h1> Comprobando Identidad... </h1>
            </div>
        )}
    </div>
  );
};

export default EmailVerification;
