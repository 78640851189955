import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
} from "react-bootstrap";
import Rating from "../components/Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstans";
import { ipApi } from "../actions/userActions";

//import axios from 'axios';

const ProductScreen = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  let [qty, setQty] = useState(1);
  let [rating, setRating] = useState(0);
  let [comment, setComment] = useState("");

  const dispatch = useDispatch();

  let [country, setCountry] = useState("");

  const productDetail = useSelector((state) => state.productDetail);
  const { loading, error, product } = productDetail;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productCreateReview = useSelector((state) => state.productCreateReview);
  const { error: errorProductReview, success: successProductReview } =
    productCreateReview;

  //const [product, setProduct] = useState({});

  useEffect(() => {
    const checkCountry = async () => {
      try {
        //country =  await ipApi();
        const data = await ipApi();
        //console.log(data);
        setCountry(data.Country);
      } catch (error) {
        console.log(error);
      }
    };
    checkCountry();
    //console.log(country);

    // const fetchProduct = async () =>{
    //   const {data} = await axios.get(`/api/products/${id}`);
    //   setProduct(data);
    // }
    // fetchProduct();

    if (successProductReview) {
      //alert("Review Submitted!");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }

    dispatch(listProductDetails(id));
  }, [dispatch, id, successProductReview]);
  //console.log(product);//

  const addToCartHandler = () => {
    navigate(`/cart/${id}?qty=${qty}`);
    //query string
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(id, {
        rating,
        comment,
      })
    );
    setRating(0);
    setComment("");
  };

  return (
    <>
      <Link className="btn btn-light my-3" to="/">
        Go Back
      </Link>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Image src={product.image} alt={product.name} fluid></Image>*
              Imagenes de referencia, el producto puede variar ligeramente en su
              presentación y color.
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h3>{product.name}</h3>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Rating
                        value={product.rating ? product.rating : 0}
                        text={`${product.numReviews} reviews`}
                      ></Rating>
                    </ListGroup.Item>
                    <ListGroup.Item>Precio: ${product.price} (MXN)</ListGroup.Item>
                    <ListGroup.Item>
                      Descripción: {product.description}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Ingredientes: {product.ingredients}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Modo de Uso: {product.modeOfUse}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <Row>
                          <Col>Estatus:</Col>
                          <Col>
                            <strong>
                              {product.countInStock > 0
                                ? "En Stock"
                                : "Agotado"}
                            </strong>
                          </Col>
                        </Row>
                      </ListGroup.Item>

                      {product.countInStock > 0 && (
                        <ListGroup.Item>
                          <Row>
                            <Col>Cantidad</Col>
                            <Col>
                              <Form.Select
                                value={qty}
                                onChange={(e) => {
                                  setQty(e.target.value);
                                }}
                              >
                                {[...Array(product.countInStock).keys()].map(
                                  (x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <Row>
                          <Col>Precio Total (Productos x Cantidad):</Col>
                          <Col>
                            <strong>${product.price * qty} (MXN)</strong>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>Costo de Envío:</Col>
                          <Col>
                            <strong>$150 (MXN)</strong>
                            <br></br>
                            Precio de envío fijo a toda la República Mexicana.
                            <br></br>
                            Gratis en compras mayores a $1,500 MXN.
                          </Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        {userInfo && /*userInfo.isAdmin &&*/ (
                          <Button
                            onClick={addToCartHandler}
                            className="btn-block"
                            type="button"
                            disabled={
                              product.countInStock <= 0
                                ? true
                                : country !== "Mexico"
                                ? true
                                : false
                            }
                          >
                            {country === "Mexico"
                              ? "Agregar al Carrito"
                              : "Sorry, we don't ship outside Mexico, if you want to buy from another country, please contact us"}
                          </Button>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="my-3">
            <Col md={6}>
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>

                    <Rating
                      value={review.rating}
                      text={review.numReviews}
                    ></Rating>

                    <p>{review.createdAt.substring(0, 10)}</p>

                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Escribe una Reseña del producto</h2>
                  {errorProductReview && (
                    <Message variant="danger">{errorProductReview}</Message>
                  )}
                  {successProductReview && (
                    <Message variant="success">
                      Review submitted successfully
                    </Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating">
                        <Form.Label>Rating</Form.Label>
                        <Form.Select
                          value={rating}
                          onChange={(e) => {
                            setRating(e.target.value);
                          }}
                        >
                          <option value="">Selecciona...</option>
                          <option value="1">1 - Malo</option>
                          <option value="2">2 - Regular</option>
                          <option value="3">3 - Bueno</option>
                          <option value="4">4 - Muy Bueno</option>
                          <option value="5">5 - Excelente</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group controlId="comment">
                        <Form.Label>Comentario</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        ></Form.Control>
                      </Form.Group>

                      <Button type="submit" variant="primary">
                        Enviar
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      <Link to="/login">ingresa</Link> para escribir una reseña{" "}
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
