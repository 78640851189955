import React from "react";
import { Container,Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer style={{ backgroundColor: "black" }}>
      <Container>
        <Row>
          <Col style={{ color: "white" }} className="text-center py-3">

            <a style={{color:"white"}} href="https://www.facebook.com/profile.php?id=61554327284986">
              <i className="fab fa-facebook fa-2x m-2"></i>
            </a>
            <a style={{color:"white"}} href="https://www.instagram.com/hadacycosmeticos/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
              <i className="fab fa-instagram fa-2x m-2"></i>
            </a>
            {/* <a style={{color:"white"}} href="/">
              <i className="fab fa-twitter fa-2x m-2"></i>
            </a> */}
            <a style={{color:"white"}} href="http://api.whatsapp.com/send?phone=523329183482">
              <i className="fab fa-whatsapp fa-2x m-2"></i>
            </a>
            <a style={{color:"white"}} href="mailto:hadacycosmeticos@gmail.com">
              <i className="fa-regular fa-envelope fa-2x m-2"></i>
            </a>

            <div>
              <Row className="mt-4">
                <Col md={3}>
                  <Nav.Link style={{color:"white"}}>Teléfono: +(52) 1 33 2918 3482 </Nav.Link>
                </Col>
                <Col md={2}>
                  <p>
                    <Nav.Link style={{color:"white"}} as={Link} href="/">Términos y Condiciones</Nav.Link>
                  </p>
                </Col>
                <Col md={2}>
                  <p>
                    <Nav.Link style={{color:"white"}} as={Link} to="/us">Nosotros</Nav.Link>
                  </p>
                </Col>
                <Col md={2}>
                  <p>
                    <Nav.Link style={{color:"white"}} as={Link} href="/">Política de Privacidad</Nav.Link>
                  </p>
                </Col>
                <Col md={3}>
                  <p>
                    <Nav.Link style={{color:"white"}} as={Link} href="/">FAQ</Nav.Link>
                  </p>
                </Col>
              </Row>
            </div>

            <p style={{color:"gray"}} className="mt-4">Copyright &copy; Hadacy Cosméticos</p>
          </Col>
        </Row>
      </Container>
    </footer>
    //     <footer style={{ backgroundColor: "black" }} className="page-footer font-small blue pt-4">
    //     <div className="container text-center text-md-left">
    //         <div className="row">
    //             <div className="col-md-6 mt-md-0 mt-3">
    //                 <h5 className="text-uppercase">Footer Content</h5>
    //                 <p>Here you can use rows and columns to organize your footer content.</p>
    //             </div>

    //             <hr className="clearfix w-100 d-md-none pb-0"/>

    //             <div className="col-md-3 mb-md-0 mb-3">
    //                 <h5 className="text-uppercase">Links</h5>
    //                 <ul className="list-unstyled">
    //                     <li><a href="#!">Link 1</a></li>
    //                     <li><a href="#!">Link 2</a></li>
    //                     <li><a href="#!">Link 3</a></li>
    //                     <li><a href="#!">Link 4</a></li>
    //                 </ul>
    //             </div>

    //             <div className="col-md-3 mb-md-0 mb-3">
    //                 <h5 className="text-uppercase">Links</h5>
    //                 <ul className="list-unstyled">
    //                     <li><a href="#!">Link 1</a></li>
    //                     <li><a href="#!">Link 2</a></li>
    //                     <li><a href="#!">Link 3</a></li>
    //                     <li><a href="#!">Link 4</a></li>
    //                 </ul>
    //             </div>
    //         </div>
    //     </div>

    //     <div className="footer-copyright text-center py-3">© 2020 Copyright:
    //         <a href="https://mdbootstrap.com/"> MDBootstrap.com</a>
    //     </div>

    // </footer>
  );
};

export default Footer;
